#navBar {
    display: flex;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #ffffff;
    z-index: 100;
    opacity: 0.9;
}

.nav-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
}

.nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-item {
    margin: 5px 15px;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    color: #6AA84F;
    text-decoration: none !important;
}

#site-logo {
    width: 100px;
    padding: 10px 10px 10px 0px;
    margin-right: auto;
    margin-left: 1.5em;
}

#hamburger {
    display: none;
}

/* Media query for mobile */
@media (max-width: 768px) {
    #navBar {
        flex-direction: column;
    }

    .nav-items {
        padding: 0;
    }

    .nav-list {
        flex-direction: column;
    }

    .list-item {
        display: none;
    }

    #hamburger {
        display: flex;
        margin: 1em 1.5em 1em auto;
        font-size: 1.5em;
    }
}

/* Extra small screens */

@media screen and (max-width: 390px) {

    #site-logo {
        width: 75px;
    }

    #hamburger {
        font-size: 1.25em;
    }
}

/* Shorter desktop displays */

@media screen and (min-width: 769px) and (max-height: 768px) {

    #navBar {
        height: 10%;
    }

    #site-logo {
        width: 85px;
    }
}