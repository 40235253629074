#projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#projects > h2 {
    font-size: 250%;
    text-align: center;
}

.carousel-container {
    width: 100%;
}

.react-multi-carousel-dot-list {
    bottom: 1% !important;
}

.custom-dots {
    font-size: 1.5rem;
    padding: 0.5rem;
}

.active {
    cursor: pointer;
    color: #6AA84F;
}

.inactive {
    cursor: pointer;
    color: #9c9c9c;
}

.project {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1rem;
    width: 80%;
}

.project-text {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.project-link {
    padding: 10px 20px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 8px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.project-link:hover {
    background-color: #6AA84F;
    color: black;
    transition: 250ms;
}

.project-link:active {
    transform: translateY(2px);
}

.project-image {
    width: 75%;
    margin: 0 auto;
    object-fit: contain;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.modal.fade {
    opacity: 1;
    visibility: visible;
}

.modal-image {
    padding: 20px;
    border-radius: 4px;
    max-height: 100%;
    transition: transform 0.3s ease;
}

.modal-image:hover {
    transform: scale(1.15);
}

@media screen and (max-width: 768px) {
    #projects > h2 {
        margin-top: 25%;
        font-size: 200%;
    }

    .project {
        flex-direction: column;
        width: 85%;
    }

    .project-text {
        margin-bottom: 0.5rem;
    }

    .project-text > p {
        font-size: 85%;
    }

    .project-image {
        width: 85%;
    }
    
    .modal-image {
        padding: 10px;
        border-radius: 4px;
        max-width: 100%;
    }
}

/* Extra small screens */

@media screen and (max-width: 390px) {

    #projects > h2 {
        margin-top: 20%;
        font-size: 150%;
    }

    .project-link {
        padding: 5px 10px;
        font-size: 0.75rem;
    }

    .project-image {
        width: 50%;
    }

    .react-multi-carousel-dot-list {
        bottom: 7% !important;
    }
}

/* Stubby short screens */

@media screen and (max-height: 650px ) {
    #projects {
        height: 125svh;
        scroll-snap-type: none;
    }

    #projects > h2 {
        font-size: 130%;
    }

    .project {
        width: 85%;
    }

    .react-multi-carousel-dot-list {
        bottom: 5% !important;
    }
}

/* Shorter desktop displays */

@media screen and (min-width: 769px) and (max-height: 768px) {
    
    #projects > h2 {
        font-size: 200%;
    }

    .project {
        width: 80%;
    }

    .project-link {
        padding: 10px 20px;
        font-size: 1rem;
    }

    .project-image {
        width: 50%;
    }

    .modal-image {
        height: 100%;
    }

    .modal-image:hover {
        transform: scale(1.3);
    }
}