#hero {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #111111;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.hero-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    gap: 3px; /* Must be changed in conjunction with line 13 of Hero.tsx */
    z-index: 1;
}

.grid__item {
    width: 15px; /* Must be changed in conjunction with line 12 of Hero.tsx */
    height: 15px; /* Must be changed in conjunction with line 12 of Hero.tsx */
    background-color: #111111;
    transition: background-color 2500ms ease-out;
}

.grid__item:hover {
    opacity: 1;
    background-color: white;
    transition: 50ms;
}

.hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin: 0 20px;
    pointer-events: none;
}

.hero-content > h1, p, button {
    pointer-events: auto;
}

.hero-content > h1 {
    margin: 0;
    font-size: 3rem;
    color: white;
}

.hero-content > p {
    margin: 1rem 0;
    font-size: 1.5rem;
    color: white;
}

.hero-content > button {
    margin: 0;
    padding: 10px 20px;
    font-size: 1.5rem;
    background-color: white;
    color: black;
    border: none;
    border-radius: 8px;
    align-self: center;
    box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.hero-content > button:hover {
    background-color: #6AA84F;
    box-shadow: 0px 0px 500px rgba(255, 255, 255, 1);
    transition: 5000ms;
}

.hero-content > button:active {
    transform: translateY(2px);
}

.portfolio-picture {
    position: relative;
    z-index: 1;
    width: 300px;
    margin: 20px;
    border-radius: 5%;
}

@media (max-width: 768px) {
    .hero-content > h1 {
        font-size: 2rem;
    }

    .hero-content > p {
        font-size: 70%;
    }

    .hero-content > button {
        font-size: 1rem;
    }

    .portfolio-picture {
        width: 40%;
    }
}