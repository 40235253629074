#contact {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 95vh;
    height: 95dvh;
    text-align: center;
    padding: 20px;
}

h2 {
    font-size: 250%;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.contact-label {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
}

.contact-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    height: 150px;
    max-width: 100%;
}

.contact-button {
    align-self: center;
    padding: 10px 20px;
    background-color: #6AA84F;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-button:hover {
    background-color: #6AA84F;
}

.form-status {
    margin-top: 20px;
    color: #9c9c9c;
}

footer {
    position: absolute;
    bottom: 1%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.contact-link {
    margin: 0 10px;
    color: #000;
    text-decoration: none;
}

.contact-link:hover {
    color: #6AA84F;
}

.contact-icon {
    width: 30px;
    height: 30px;
    margin: 10px;
}

@media screen and (max-width: 768px) {
    .contact-form {
        width: 85%;
    }
}

/* Extra small screens */

@media screen and (max-width: 390px) {
    h2 {
        font-size: 175%;
        margin-top: 2rem;
    }

    textarea {
        height: 50px;
    }
}

/* Shorter desktop displays */

@media screen and (min-width: 769px) and (max-height: 768px) {
    h2 {
        font-size: 200%;
        margin-top: 5%;
    }

    .contact-form {
        width: 40%;
    }

    .contact-label {
        font-size: 14px;
    }

    .contact-input {
        padding: 8px;
    }

    textarea {
        height: 100px;
    }

    .contact-button {
        padding: 8px 16px;
    }

    .form-status {
        font-size: 14px;
    }

    footer {
        bottom: 2%;
    }

    .contact-link {
        margin: 0 5px;
    }

    .contact-icon {
        width: 25px;
        height: 25px;
        margin: 5px;
    }
}