#about {
    display: flex;
    margin: auto;
    position: relative;
}

.circles {
    position: absolute;
    top: 10%;
    z-index: 0;
}

#green-circle {
    top: 2.5%;
    
}

.about-text {
    width: 50%;
    margin: 0 50px;
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    opacity: 0.9;
}

.about-text > h2 {
    align-self: flex-start;
}

.about-text > h3 {
    margin: 0 50px;
    text-align: right;
}

.about-text > p {
    align-self: flex-start;
    text-rendering: auto;
}

a:hover {
    text-decoration: underline;
}

iframe {
    width: 50%;
    height: 75%;
    margin-right: 50px;
    z-index: 2;
    opacity: 0.9;
}

@media screen and (max-width: 768px) {
    #about {
        flex-direction: column;
        height: 175svh;
        scroll-snap-type: none;
    }

    .about-text {
        width: 85%;
        margin: 25% 5px 0 5px;
    }

    iframe {
        width: 85%;
        margin: 25px 5px 20px 5px;
    }

    .green-circle {
        top: 2.5%;
        left: 50%;
        transform: translateX(-50%); /* Center the circle horizontally */
    }
}

/* Extra small screens */

@media screen and (max-width: 390px) {
    #about {
        height: 200svh;
    }

    .green-circle {
        top: 3.5%;
    }
}

/* Stubby short screens */

@media screen and (max-height: 650px ) {
    #about {
        height: 250svh;
    }

    .about-text {
        width: 85%;
        margin: 15% 5px 0 5px;
    }

    .about-text > h2 {
        font-size: 130%;
    }

    .about-text > h3 {
        font-size: 110%;
    }

    .green-circle {
        top: 4.5%;
    }
}

/* Shorter desktop displays */

@media screen and (min-width: 769px) and (max-height: 768px) {

    #about {
        height: 150svh;
    }

    .about-text {
        width: 50%;
        margin: 0 50px;
    }

    .about-text h2 {
        font-size: 200%;
    }

    .about-text h3 {
        font-size: 150%;
    }

    .about-text p {
        font-size: 100%;
    }

    iframe {
        width: 50%;
        height: 75%;
        margin-right: 50px;
    }
}