#scroll-container {
    height: 100vh;
    height: 100dvh;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
}

section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    scroll-snap-align: start;
}

@media screen and (max-width: 768px){
    #scroll-container {
        height: 100svh;
        scroll-snap-type: y proximity;
    }

    section {
        height: 100svh;
    }
}

/* Shorter desktop displays */

@media screen and (min-width: 769px) and (max-height: 768px) {

    #scroll-container {
        scroll-snap-type: y mandatory;
    }
}